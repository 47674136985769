<template>
  <ig-page-base
    :is-boxed="true"
    :title="$t('pagePrivacyPolicy.title')"
    class="page-privacy-policy"
    type="large"
  >
    <!-- eslint-disable -->
    <div class="content">
      <div>
        <h4>1. Coleta de Dados Gerais</h4>

        <p>
          A prioridade da Integrai Tecnologia LTDA é assegurar a privacidade e a segurança das
          informações dos usuários cadastrados (“Usuário”).
        </p>

        <p>
          Portanto, esta Política de Privacidade tem por principal objetivo definir as políticas
          adotadas pela Integrai Tecnologia LTDA quanto à coleta, uso e divulgação de informações
          pessoais. Ao utilizar a Integrai Tecnologia LTDA e dar o seu aceite nesta Política de
          Privacidade no momento do cadastro, o usuário reconhece e aceita a adoção das práticas,
          conforme descrito neste Instrumento, conforme previsto na legislação brasileira,
          principalmente o Marco Civil da Internet e a Lei de Proteção de Dados Pessoais.
        </p>

        <p>
          A Integrai Tecnologia LTDA se reserva o direito de modificar esta Política de Privacidade
          a qualquer momento, para atender aos requisitos legais e/ou de tecnologia. Todas as
          modificações serão avisadas através dos nossos veículos oficiais de comunicação com o
          Usuário com a data de início de vigência, sendo que o Usuário, ao permanecer no sistema,
          demonstra que concorda com as modificações realizadas.
        </p>
      </div>

      <div>
        <h4>2. Cadastro</h4>

        <p>
          O objetivo da Integrai Tecnologia LTDA, ao permitir que o Usuário voluntariamente coloque
          suas informações, é facilitar a sua navegação e o uso de suas ferramentas e, quando já
          integradas com os sistemas dos meios de pagamento digitais, a realização das transações
          comerciais, conforme comandos inseridos pelos próprios Usuários. Portanto, a pró-atividade
          do Usuário em inserir os seus dados significa que ele quer ser beneficiado com as
          ferramentas da Integrai Tecnologia LTDA e concorda com os termos de privacidade, inclusive
          com o seu compartilhamento com terceiros, afiliados e/ou parceiros da Integrai Tecnologia
          LTDA e, dentre estes, sistemas de pagamentos digitais para a correta prestação dos
          serviços. Ao selecionar fornecedores e parceiros, a Integrai Tecnologia LTDA tem em
          consideração os respectivos processos de tratamento de dados e a preocupação de manter a
          devida segurança para o não compartilhamento indevido.
        </p>

        <p>
          Para se tornar um Usuário, o primeiro passo é o seu cadastro, onde nome do representante
          (ou autorizado para contratar), nome da empresa, telefone de contato, e-mail, senha e
          dados complementares como, por exemplo, "Url da loja" são obrigatórios. Dados
          complementares são uma fonte de informação para a Integrai Tecnologia LTDA, o que facilita
          a recomendação de integrações e funcionalidades mais adequadas para a realidade do
          Usuário. Caso o Usuário necessite de recursos mais avançados ele pode assinar um plano
          pago, neste caso, dados para pagamentos também se tornam obrigatórios (razão social
          completa, CNPJ, no caso de pessoa jurídica, e nome completo e / CPF, no caso de pessoa
          física, por exemplo).
        </p>

        <p>
          No caso do Usuário inserir dados pessoais de terceiros, para a correta prestação dos
          serviços da Integrai Tecnologia LTDA, o Usuário fica única e exclusivamente responsável
          por garantir que este terceiro tenha autorizado a inserção de seus dados, isentando desde
          já a Integrai Tecnologia LTDA de qualquer indenização em virtude desse tratamento de dados
          do terceiro, mesmo que este não seja propriamente o Usuário da Integrai Tecnologia LTDA.
        </p>

        <p>
          Algumas informações podem/deverão ser colocadas tanto durante o cadastro, quanto em um
          momento posterior de preferência do Usuário para alterar seu perfil pessoal e/ou de sua
          empresa, e/ou ainda para requerer o acesso a determinadas ferramentas. Dentre as
          possibilidades de informações adicionais, vale reforçar que os dados pessoais do usuário
          nunca serão exibidos para os demais Usuários e o Usuário somente terá acesso integral à
          Integrai Tecnologia LTDA mediante login e senha. Ressalta-se, ainda, que o login e a senha
          que dão acesso ao cadastro do Usuário são de uso pessoal e intransferível deste, razão
          pela qual a Integrai Tecnologia LTDA não se responsabiliza por eventual manipulação não
          autorizada dessas informações por terceiros e devendo, portanto, o Usuário tomar todas as
          medidas necessárias para manter em sigilo as referidas informações.
        </p>

        <p>
          O Usuário tem direito de acessar, modificar, corrigir e eliminar os dados sobre ele
          referentes à sua decisão de ter se tornado um Usuário. Se o Usuário revogar o seu
          consentimento e autorização do uso dos seus dados na Integrai Tecnologia LTDA, este
          removerá seus dados para futuras consultas, mas algumas informações permanecerão
          arquivadas para o fim único e exclusivo da Integrai Tecnologia LTDA poder atender aos
          requisitos legais do Marco Civil da Internet e do seu interesse legítimo, nas formas
          permitidas pela legislação.
        </p>

        <p>
          Para mudanças mais importantes, a Integrai Tecnologia LTDA faz uso de criptografia de
          senha e protocolos de confirmação via e-mail.
        </p>

        <p>
          O Usuário compromete-se a notificar a Integrai Tecnologia LTDA imediatamente, através da
          seção “CENTRAL DE AJUDA” no Site https://integrai.com.br (https://ajuda.integrai.com.br),
          a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado
          por terceiros a mesma. O Usuário será o único responsável pelas operações efetuadas em sua
          conta, uma vez que o acesso a mesma só será possível mediante a aposição da senha, cujo
          conhecimento é exclusivamente do Usuário.
        </p>
      </div>

      <div>
        <h4>3. Informações pessoais coletadas pela Integrai Tecnologia LTDA</h4>

        <p>
          A Integrai Tecnologia LTDA utiliza-se dos recursos de “cookies” e “web beacons” (dados
          automaticamente baixados no computador do Usuário que demonstram as suas atividades na
          internet, dados demográficos, fluxo de tráfego e comportamento dos Usuários) para permitir
          sua correta identificação, além de melhorar a qualidade das informações oferecidas em seu
          portal para os próprios Usuários. O uso regular de cookies e web beacons é uma prática
          aceitável na indústria, pois permite o armazenamento de informações importantes, como, por
          exemplo, os acessos do Usuário na Integrai Tecnologia LTDA, para posteriormente não ser
          apresentado um conteúdo repetido ou ultrapassado e sempre buscar a melhora na experiência
          de uso da Integrai Tecnologia LTDA pelo Usuário. Se não for a vontade do Usuário receber
          cookies, ou o mesmo desejar uma notificação prévia antes de seu uso, ele deverá programar,
          a seu critério, seu Web browser (navegador da Web) com estas opções, caso seu browser
          disponha do recurso.
        </p>

        <p>
          Além das informações pessoais fornecidas, a Integrai Tecnologia LTDA tem a capacidade
          tecnológica de recolher outras informações técnicas, como o endereço de protocolo de
          Internet do Usuário, o sistema operacional do computador, o tipo de browser e o endereço
          de websites de referência.
        </p>

        <p>
          Conforme já determinado anteriormente, a Integrai Tecnologia LTDA não fornecerá as
          informações do Usuário a terceiros sem prévia autorização do mesmo, com exceção de casos
          onde são necessários para responder às solicitações judiciais de entidades governamentais,
          somente nos limites do Marco Civil da Internet e da Lei de Proteção de Dados Pessoais, com
          o propósito de proteção de direitos, propriedades e/ou segurança do Integrai Tecnologia
          LTDA, de seus Usuários e/ou do público em geral (terceiros, na forma da lei).
        </p>

        <p>
          A Integrai Tecnologia LTDA deverá, a seu critério, examinar as informações armazenadas nos
          seus bancos de dados com o propósito de identificar Usuários com múltiplas identidades ou
          pseudônimos para fins legais e/ou de segurança, para dirimir quaisquer disputas,
          solucionar problemas, e garantir que os Usuários não estejam utilizando a Integrai
          Tecnologia LTDA para fins ilícitos, nos termos e condições da presente Política de
          Privacidade. Em outra hipótese, se a Integrai Tecnologia LTDA for obrigado por lei, ordem
          judicial ou outro processo legal, a divulgar alguma informação pessoal do Usuário, não
          hesitará em cooperar com estes agentes, nos limites previstos no Marco Civil da Internet e
          da Lei de Proteção de Dados Pessoais. Assim, por meio deste instrumento, o Usuário
          autoriza a Integrai Tecnologia LTDA a divulgar estas informações pessoais para atender
          somente aos fins acima indicados.
        </p>

        <p>
          A Integrai Tecnologia LTDA ainda declara para os devidos fins que envida seus melhores
          esforços para garantir a proteção da privacidade dos Usuários e manter a respectiva
          exatidão, com sistemas de segurança que garantem a proteção total destes dados, com
          salvaguardas físicas, administrativas e técnicas razoáveis para proteger as informações
          pessoais do Usuário com relação a acessos, utilização ou divulgação não autorizados. A
          Integrai Tecnologia LTDA tem como política a não participação na prática de venda ou
          intercâmbio de dados pessoais com terceiros para fins promocionais e também requer que os
          seus fornecedores protejam tais informações de acesso, utilização ou divulgação não
          autorizados.
        </p>
      </div>

      <div>
        <h4>4. Permissão para a Integrai Tecnologia LTDA processar informações sobre o Usuário</h4>

        <p>
          As informações cedidas pelo Usuário e registradas devido ao uso do sistema e que não o
          torne identificável pessoalmente (como o padrão de navegação, por exemplo), os denominados
          dados anonimizados, serão utilizadas pela Integrai Tecnologia LTDA e servirão como insumos
          para mapeamento de informações de mercado, formação de estatísticas e direcionamento de
          ações de marketing. Através do cadastramento, uso e fornecimento de informações à Integrai
          Tecnologia LTDA, o Usuário deliberadamente aceita os Termos de Uso e condições da Política
          de Privacidade sobre o uso de suas informações anonimizadas.
        </p>

        <p>
          As informações cedidas pelo Usuário que o tornam pessoalmente identificável, conforme
          indicadas no item 2 acima, tem como único objetivo fazer com que os Usuários da Integrai
          Tecnologia LTDA se relacionem com as ferramentas ali disponíveis e/ou com os outros
          Usuários, de forma a atender, única e exclusivamente, os comandos realizados por estes.
        </p>

        <p>
          Além disso, as informações fornecidas são usadas para: (i) administrar a conta dos
          Usuários a fim de customizar cada vez mais os serviços; (ii) processar os comandos
          solicitados pelos próprios Usuários diretamente na Integrai Tecnologia LTDA, tais como as
          ferramentas requisitadas no ato do cadastro ou de forma posterior e/ou o débito dos
          valores nos cartões de crédito, quando expressamente informados pelos Usuários; (iii)
          comunicar atualizações da Integrai Tecnologia LTDA; (iv) comunicar novidades e ações de
          marketing da Integrai Tecnologia LTDA ou de parceiros; e/ou (v) quando exigido pelas
          autoridades, nos limites do Marco Civil da Internet e da Lei de Proteção de Dados
          Pessoais, atender a pedidos de investigação destes órgãos.
        </p>

        <p>
          Neste sentido, para fechar a sua conta na Integrai Tecnologia LTDA, atualizar os seus
          dados, corrigir os mesmos ou pedir o seu descastramento das mensagens recebidas, direito
          este garantido ao Usuário na forma da lei, este poderá acessar o link de descadastramento
          no final dos e-mails, o painel de configurações diretamente na Integrai Tecnologia LTDA ou
          mediante pedido aos administradores do site pelo e-mail contato@integrai.com.br.
        </p>

        <p>
          Portanto, quando é indicado que a Integrai Tecnologia LTDA utiliza as informações pessoais
          do Usuário no âmbito de um pedido, comando, transação ou acordo (ou para a preparação dos
          mesmos), ou ainda para fornecer ao Usuário serviços solicitados pelo mesmo (tais como
          acesso à Integrai Tecnologia LTDA), essa informação é requerida por ser necessária para a
          correta prestação dos serviços ao Usuário.
        </p>

        <p>
          Quando é indicado que a Integrai Tecnologia LTDA utiliza as informações pessoais do
          Usuário para fins de marketing, melhoria ou desenvolvimento de seus produtos e/ou
          serviços, para motivos de segurança, ou por requisitos regulamentares fora do âmbito do
          acordo ou pedido do Usuário, esta alteração é efetuada com base nos interesses legítimos
          da Integrai Tecnologia LTDA ou de terceiros ou com a autorização do Usuário.
        </p>
      </div>

      <div>
        <h4>5. As obrigações do Usuário</h4>

        <p>
          É dever do Usuário obedecer aos atuais Termos de Uso, como publicado pela Integrai
          Tecnologia LTDA em seu site, e à presente Política de Privacidade, o que inclui respeitar
          propriedade intelectual de terceiros.
        </p>

        <p>
          O Usuário não deve executar download, upload ou qualquer forma de disseminação de material
          ou informação de caráter violento, ofensivo, racista ou xenofóbico ou qualquer um que
          viole o espírito e objetivos da Integrai Tecnologia LTDA e sua comunidade de Usuários.
        </p>

        <p>
          O Usuário não deve prover à Integrai Tecnologia LTDA e/ou a qualquer outro Usuário
          informações que ele acredite serem danosas à sua pessoa, profissional ou socialmente.
          Dessa forma, ao publicar comentários em locais públicos da Integrai Tecnologia LTDA ou em
          qualquer canal de comunicação da mesma, o Usuário deve ter em conta que estas informações
          serão visualizadas por outros Usuários e o público em geral.
        </p>
      </div>

      <div>
        <h4>6. Usuários Menores de Idade</h4>

        <p>
          Informações de menores de idade não poderão ser submetidas à Integrai Tecnologia LTDA por
          pessoas com idade inferior a 18 anos, sem o prévio consentimento dos pais ou responsáveis.
          É terminantemente proibido que menores de idade naveguem e façam uso das diversas
          ferramentas da Integrai Tecnologia LTDA, sem o referido consentimento.
        </p>

        <p>
          Caso informações de menores de idade sejam submetidas à Integrai Tecnologia LTDA, pedimos
          que o Usuário apresente o consentimento específico, dado por pelo menos um dos pais ou
          responsáveis legais, em nome do menor. Com a aceitação dos termos previstos nesta Política
          de Privacidade, no ato do cadastro, ficará tacitamente entendido que este consentimento
          foi dado pelos pais ou responsáveis e que as informações inseridas no cadastro, junto com
          as informações dos menores de idade, foram também fornecidas por estes pais ou
          responsáveis, sendo de responsabilidade exclusiva destes o conteúdo acessado pelos menores
          de idade.
        </p>
      </div>

      <div>
        <h4>7. Período de Retenção e Exportação dos Dados</h4>

        <p>
          A Integrai Tecnologia LTDA permite que o Usuário faça a consulta de seus dados de
          automatizações (logs de entrada e saída, por exemplo), e/ou o descadastramento de e-mails
          comerciais e de marketing, a qualquer tempo, mediante simples acesso ao painel de
          configurações da conta dentro da Integrai Tecnologia LTDA.
        </p>

        <p>
          Da mesma forma, o Usuário poderá, a qualquer momento com um simples acesso ao painel de
          controle da Integrai Tecnologia LTDA, pedir a exclusão de dados de sua conta (e-mail
          cadastrado, senha e dados da empresa), dados das automatizações (logs de entradas e saída,
          por exemplo) e contas conectadas (os tokens usados , por exemplo). No entanto, ao cancelar
          o seu cadastro, o Usuário não terá mais como acessar o seu histórico na Integrai
          Tecnologia LTDA, nem exportar os seus dados. Isso deverá ser feito antes do Usuário pedir
          a exclusão da Integrai Tecnologia LTDA. Portanto, após o pedido de exclusão, a Integrai
          Tecnologia LTDA não terá mais acesso a disponibilidade dos dados do Usuário, sendo que o
          Usuário tem ciência que, pela legislação em vigor, este possui uma obrigação de manter um
          backup físico destes arquivos pelos prazos exigidos em lei, independente dos serviços e/ou
          produtos fornecidos pela Integrai Tecnologia LTDA.
        </p>

        <p>
          Concomitantemente, a Integrai Tecnologia LTDA possui uma rotina própria de exclusão de
          dados retidos há mais de três meses (logs de entrada e saída, por exemplo). A Integrai
          Tecnologia LTDA não irá reter informações pessoais por mais tempo do que o necessário para
          o cumprimento da finalidade para as quais as mesmas foram processadas, incluindo a
          segurança do processamento em conformidade com as obrigações jurídicas e regulamentares
          (por exemplo, auditoria, contabilidade e termos de retenção estatutária), litígios quanto
          ao processamento, bem como para o estabelecimento, exercício ou defesa de seu interesse
          legítimo.
        </p>

        <p>
          Não obstante, a Integrai Tecnologia LTDA se dá ao direito de arquivar certos dados, sempre
          anonimizados, para análises estatísticas, por exemplo, taxa de crescimento de uso de uma
          ferramenta integrada à Integrai Tecnologia LTDA.
        </p>
      </div>

      <div>
        <h4>8. Canal de Comunicação com o Usuário</h4>

        <p>
          Se o Usuário tiver qualquer dúvida sobre a presente Política de Privacidade ou sugestão
          sobre a Integrai Tecnologia LTDA, ele poderá encaminhar um e-mail para
          contato@integrai.com.br.
        </p>

        <p>
          A sua mensagem será encaminhada para empresa responsável pelo tratamento das informações
          do Usuário, à luz da Lei de Proteção de Dados Pessoais, que, para todos os fins e efeitos,
          é a Integrai Tecnologia LTDA
        </p>
      </div>

      <div>
        <h4>9. Direito de Apresentar Queixa</h4>

        <p>
          Se o Usuário considerar que o processamento das suas informações pessoais, por parte da
          Integrai Tecnologia LTDA, não está em conformidade com as leis de proteção de dados
          aplicáveis, tem o direito de apresentar queixa: (i) diretamente através da Integrai
          Tecnologia LTDA, mediante envio de um e- mail para contato@integrai.com.br ; e/ou (ii)
          junto às autoridades de proteção de dados competentes.
        </p>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { IgPageBase } from '@integrai/ui';

export default Vue.extend({
  name: 'page-term-and-conditions',
  components: {
    IgPageBase,
  },
});
</script>

<style lang="scss">
.page-privacy-policy {
  h4 {
    margin-top: 5rem !important;
  }
  ul {
    list-style: lower-alpha;
    li {
      margin-bottom: 1rem;
    }
  }
}
</style>
